.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.name {
    padding-left: 30px;
    padding-top: 1rem;
}
.title {
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .22rem;
}
.bass {
    font-size: 2.7rem;
    letter-spacing: .18rem;
    font-weight: 800;
    margin: 0;
    padding-left: 0;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0px;
}

.li {
    list-style-type: none;
    margin-right: 20px;
    font-weight: 300;
    color: var(--primary-100);
}

.li:hover {
    text-decoration: underline;
    color: var(--tertiary-100);
}


a {
    text-decoration: none;
    color: inherit;
}
a:active {
    text-decoration: underline;
}

.mobile_btn {
    display: none;
}

@media screen and (max-width: 900px) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        z-index: 10;
    }
    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;
        top: -10;
        width: 100%;
        height: 100vh;

        background-color: #333333ed;
        z-index: 10;
        transition: left 1s;
    }
    .title {
        font-size: 1.25rem;
    }
    .bass {
        font-size: 1.5rem;
    }
    .active {
        left: 0;
        text-align: center;
    }
    .navbar a {
        font-size: 2rem;
    }
}