.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.4rem;
    width: 95%;
    margin: 20px auto;
}

.nPhoto {
    margin-top: 20px;
    width: 100%;
}

.nResume {
    margin-top: 20px;
    width: 100%;
}

.img {
    object-fit: fill;
    width: 100%;
    height: 90%;
}

.btn {
    display: block;
    margin: 30px auto;
}
.prep {
    padding: 30px;
    font-size: 1.4rem;
    margin-top: -50px;
    font-weight: 300;
}

.ul {
    margin-top: 20px;
}

.li {
    list-style-type: none;
    padding: 20px;
    background: var(--primary-200);
    color: #333;
    min-width: 360px;
    display: grid;
    grid-template-columns: 150px 1fr 1fr;
    grid-gap: 2rem;
}

.li:nth-child(odd) { 
    background: var(--secondary-200);
    color: var(--primary-200);
}

@media screen and (max-width: 700px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 600px) {
    .li {
        padding: 10px 5px;
        font-size: 1rem;
        grid-template-columns: 100px 1fr 1fr;
        grid-gap: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .ul {
        position: absolute;
        left:0;
        right: 0;
    }
}