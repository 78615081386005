.container {
    min-height: 350px;
    min-width: 500px;
    margin-left: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    background: rgb(99, 99, 99);
    color: var(--primary-100);
    padding: 30px;
    box-shadow: var(--shadow-light);
    position: relative;
}

.h3 {
    font-size: 2rem;
    font-weight: 500;
}

.a {
    font-size: 1.3rem;
    font-weight: 300;
    text-decoration: underline;
    color: var(--primary-100);
}
.a:hover {
    color: var(--tertiary-100);
}
.a2 {
    font-size: 1.3rem;
    font-weight: 300;
    color: var(--primary-100);
}

.ul {
    margin-top: 20px;
    /* border: 2px solid blue; */
    height: 120px;
    width: 240px;
    overflow-y: auto;
    padding-left: 20px;
}

.li {
    font-size: 1.2rem;
    list-style-type:circle;
    font-weight: 300;
}

.img {
    height: 200px;
    width: 200px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    background-color: black;
    border: 2px solid var(--primary-100);
}

.p {
    position: absolute;
    bottom: 12px;
    /* border: 2px solid blue; */
    max-width: 250px;
}

@media screen and (max-width: 560px) {
    .container {
        min-width: 200px;
    }

    .img {
        width: 130px;
        height: 130px;
        bottom: 20px;
        right: 20px;
        
    }
}

@media screen and (max-width: 400px) {
    .container {
        position: static;
        display: block;
        margin: auto;
        width: 100%;
    }

    .h3 {
        font-size: 1.4rem;
    }

    .img {
        position: static;
        display: block;
        margin: 10px auto;
    }

    .p {
        position: static;
    }
}