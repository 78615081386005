@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}
:root {
    --primary-100: hsl(0, 0%, 97%);
    --primary-200: hsl(0, 0%, 89%);
    --secondary-100: hsl(26, 19%, 69%);
    --secondary-200: hsl(26, 21%, 53%);
    --tertiary-100: hsl(120, 70%, 59%);
    --tertiary-200: hsl(120, 100%, 25%);
    --background: hsl(300, 1%, 25%);
    --shadow-light: hsla(0, 0%, 7%, 0.411) 0 1px 10px;
}
body {
    background: var(--background);
    color: var(--primary-100);
}
h1 {
    font-size: 3rem;
    padding: 30px;
}
h2 {
    margin-top: 50px;
    margin-left: 30px;
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 500;
}
button {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 15px 25px;
    background-color: var(--secondary-200);
    border-color: var(--secondary-200);
    border-radius: 30px;
    border-style: solid;
    color: var(--primary-100);
    transition: all .3s ease-out;
}
button:hover {
    background: var(--tertiary-200);
    border-color: var(--tertiary-200);
    cursor: pointer;
}

@media screen and (max-width: 760px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 2rem;
    }
}