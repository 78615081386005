.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    padding: 30px;
}

.img {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.container {
    width: 100%;
}

.name {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.name input {
    width: 30%;
    height: 60px;
    font-size: 1.2rem;
    padding: 10px;
    border-style: none;
}

.name input:focus {
    outline: none;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content input {
    height: 60px;
    font-size: 1.2rem;
    margin-bottom: 30px;
    width: 73.3333%;
    padding: 10px;
    border-style: none;
}

.content input:focus {
    outline: none;
}

.message {
    height: fit-content;
    font-size: 1.2rem;
    margin-bottom: 30px;
    width: 73.3333%;
    padding: 10px;
    border: none;
    resize: none;
}

.message:focus {
    outline: none;
}

.btn {
    display: block;
    margin: 30px auto;
}


@media screen and (max-width: 880px) {
    .grid {
        display: block;
        z-index: -1;
    }

    .container {
        position: absolute;
        top: 270px;
        right: 0px;
    }

    .name input, .content input, .message {
        background-color: rgba(255, 255, 255, 0.9);
        opacity: 0.6;
        transition: .3s;
        box-shadow: var(--shadow-light);
        }
    .name input:focus, .content input:focus, .message:focus {
        opacity: 1;
    }
    .btn {
        margin: -10px auto;
        border: 2px solid var(--primary-100);
        box-shadow: var(--shadow-light);
    }
    .btn:hover {
        border: 2px solid var(--primary-100);
    }
}

@media screen and (max-width: 760px) {
    .container {
        top: 250px;
    }
}
@media screen and (max-width: 400px) {
    .message {
        height: 160px;
    }
}