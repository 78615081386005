.container {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.2rem; */
    width: 100%;
    /* border: 2px solid red; */
}
.content {
    /* border: 2px solid orange; */
    float: none;
}
.p {
    padding: 0px 30px 30px;
    font-size: 1.4rem;
    font-weight: 300;
    max-width: 75ch;
}
.picture {
    float: right;
    width: 50%;
    /* border: 2px solid yellow; */
    margin: 0 0 30px 30px;
}

.first_date {
    margin-top: -10px;
}

.button {
    margin: 30px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-200);
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}
.button:hover {
    background-color: #333;
    color: var(--primary-100);
}
.a {
    color: #333;
    transition: all 0.3s ease;
}
.a:hover {
    color: var(--primary-100);
}

.upcoming_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 30px;
}

.previous_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 30px;
}

.img {
    height: auto;
    width: 95%;
    margin-top: 3px;
    margin: auto;
}

.img_label {
    text-align: right;
    padding-right: 30px;
}

/* ---------------Upcoming Events---------------- */

@media screen and (max-width: 1090px) {
    .previous_container {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
    }

    .upcoming_container {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
    .picture {
        float: none;
        width: 100%;
        margin: 10px;
    };
    .img {
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        padding-right: 20px;
    }
}

@media screen and (max-width: 500px) {
    .previous_container {
        padding-right: 0px;
    }

    .upcoming_container {
        padding-right: 0px;
    }
}