.swiperSmall {
    display: none;
}

.mySwiper {
    margin: 30px;
    height: 430px;
    width: 95%;
}

.slide {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
}

.img {
    height: 400px;
    width: 400px;
    object-fit: cover;
    box-shadow: var(--shadow-light);
}

.videos {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 50vh;
    grid-gap: 3rem;
    justify-content: space-evenly;
}

.video {
    box-shadow: var(--shadow-light);
}
.p {
    padding: 30px;
    padding: 0 30px 20px;
    font-size: 1.4rem;
    font-weight: 300;
    max-width: 75ch;
}
.embold {
    font-weight: 600;
}

.audioGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.audio .img {
    margin: 30px;
}
.audioSamples {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-evenly;
    padding: 30px;
}
.audioGrid .p {
    margin: 0 -30px -30px;
}

.socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3rem;
    margin: 20px 30px 80px;
    max-width: 320px;
    
}

.icon {
    transition: all .2s ease-in;
}

.instagram {
    background-image: linear-gradient(30deg,#3F3E3F, #3F3E3F, #3F3E3F, #3F3E3F, #3F3E3F);
    border-radius: 10px;
    transition: .2s ease-in;
}
.instagram:hover {
    z-index: -1;
    background-image: linear-gradient(30deg,#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
    border-radius: 10px;
}

.facebook:hover {
    color: #3b5998;
}

.youtube {
    font-size: 4rem;
}
.youtube:hover {
    color: #c4302b;
}

.linkedin:hover {
    color: #0072b1
}

.tiktokdiv {
    position: relative;
}
.tiktok {
    font-size: 2.43rem;
    margin-bottom: 3px;
}
.tiktok:hover {
    z-index: 10;
}
.tiktok1 {
    color: #69C9D0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 2.43rem;
    z-index: -4;
}
.tiktok2 {
    color: #EE1D52;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    position: absolute;
    top: 4px;
    left: 0;
    font-size: 2.43rem;
    z-index: -1;
}
.tiktokdiv:hover .tiktok1 {
    opacity: 1;
    transform: translate(1px, 1px);
}
.tiktokdiv:hover .tiktok2 {
    opacity: 1;
    transform: translate(-1px, -1px);
}

@media screen and (max-width: 860px) {
    .videos {
        grid-template-columns: 1fr;
    }

    .swiperBig {
        display: none;
    }

    .swiperSmall {
        display: block;
    }
    .audioGrid {
        grid-template-columns: 1fr;
    }
    .audioGrid .p {
        margin: 30px -30px -15px;
    }
}

@media screen and (max-height: 600px) {
    .videos {
        grid-auto-rows: 80vh;
    }
}