.hero {
    background: url('../images/Headshot3rs.webp') no-repeat center/cover;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .hero {
        background: url('../images/Norman_HS31rs.webp') no-repeat center/cover;
    }
}