.p {
    padding: 0px 30px 30px;
    font-size: 1.4rem;
    font-weight: 300;
}

.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.2rem;
}

.btn {
    display: block;
    margin: 0 auto 20px;
}

.img {
    height: auto;
    width: 95%;
    margin-top: 3px;
    margin: auto;
}

.img_label {
    text-align: right;
    padding-right: 30px;
    margin-bottom: 20px;
}

/* ---------------Upcoming Events---------------- */

@media screen and (max-width: 1090px) {
    .container {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
    .img {
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
}